import { areaName, enterenceImg, shopInfo } from "../core/shopdata"
import { Link } from "react-router-dom"


export function Enterence(){

    return(
        <main id="enterence">
            <article className="text-center">
                <header>
                    <div className="first text-primary"><img className="logo-sm" src="/img/logo-ogoto.svg" alt={`${areaName}のソープランド【${shopInfo.name}`}/></div>
                    <h1><img src={enterenceImg} alt={areaName + 'のソープランド【' + shopInfo.name + `】のイメージバナー`} /></h1>
                    <div className="group-logo-wrap -mt">
                        <a href="https://marineblue-g.com/" target="_blank">
                            <img src="/img/marin-group-logo.svg" alt="マリングループ ロゴ" width="250" height="50"/>
                        </a>
                    </div>
                </header>

                <p>
                    <img src="/img/18txt.png" width="61" alt="当サイトはアダルトコンテンツを含みます。18歳未満の方のご利用は固く禁じられています。" />
                </p>
                <p className="alert">
                    当サイトはアダルトコンテンツを含みます。<br/>
                    18歳未満の方のご利用は固く禁じられています。<br/>
                    <br/>
                    18歳未満の方は<a href="https://www.yahoo.co.jp/" className="underline">コチラ</a>からご退出ください。
                </p>

                <div className="attention-container">
                    <div className="attention-box">
                        当店は暴力団を含む反社会的団体<br/>
                        及びスカウト等との関わりは一切ございません。
                    </div>
                    <div class="attention-wrap">
                        <div class="title">■加盟協会・団体</div>
                        <ul className="group-box">
                            <li>・全日本特殊浴場協会連合会</li>
                            <li>・一般社団法人東京特殊浴場暴力団等排除推進協議会</li>
                            <li>・一般社団法人全国防犯健全協力会</li>
                            <li>・東京防犯健全協力会</li>                        
                        </ul>
                    </div>
                </div>


                <div>
                    <Link to="/home" className="btn-enter"><span>ENTER</span><br />18歳以上のお客様ページ</Link>
                </div>
                
            </article>
            <section className="text-center">
                <div>
                    <p style={{textAlign: "center",marginBottom: "5px"}}>
                        <a href="https://marineblue-g.com/" target="_blank" rel="norefarrer noopner">
                            <img src="https://marineblue-g.com/img/bnr-group_20240701.jpg" width="468px" alt="マリングループ" />
                        </a>
                    </p>
                </div>
                <div>
                    <p style={{textAlign: "center",marginBottom: "5px"}}>
                        <a href="https://www.cityheaven.net/shiga/A2501/A250101/barubora_marinblue_ogoto/?of=y2" target="_blank">
                            <img src="https://img.cityheaven.net/tt/inline/shop/heavennetlink_ohp_red01.png" width="468" height="80" border="0" alt="ヘブンネット"/>
                        </a>
                    </p>
                </div>
                <div>
                    <p style={{textAlign: "center",marginBottom: "5px"}}>
                        <a href="https://www.girlsheaven-job.net/shiga/ma-52/sa-479/barubora_marinblue_ogoto/?of=y" target="_blank">
                            <img src="https://img.cityheaven.net/tt/inline/shop/girlsheavenlink_ohp_pink02.png" width="468" height="80" border="0"alt="ガールズヘブン"/>
                        </a>
                    </p>
                </div>
                <div>
                    <p style={{textAlign: "center",marginBottom: "5px"}}>
                        <a href="https://mensheaven.jp/8/barubora_marinblue_ogoto/?of=y" target="_blank">
                            <img src="https://img.cityheaven.net/tt/inline/shop/jobheavenlink_ohp02.png" width="468" height="80" border="0"alt="ジョブヘブン"/>
                        </a>
                    </p>
                </div>

                <div style={{display: "flex", alignItems: "center", justifyContent: "center",  marginBottom: "10px"}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div><a style={{display: "block"}} href="https://www.cityheaven.net/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/heaven.gif" alt="ヘブンネット" width="236" height="68" border="0" /></a></div>
                        <div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <a style={{display: "block"}}  href="https://www.cityheaven.net/osaka/shop-list/biz6/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/01_osaka.jpg" alt="大阪デリヘル" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.cityheaven.net/hyogo/shop-list/biz6/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/02_hyogo.jpg" alt="兵庫デリヘル" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.cityheaven.net/kyoto/shop-list/biz6/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/03_kyoto.jpg" alt="京都デリヘル" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.cityheaven.net/shiga/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/04_shiga.jpg" alt="滋賀風俗" width="58" height="34" border="0" /></a>
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <a style={{display: "block"}} href="https://www.cityheaven.net/shiga/A2501/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/05_ogoto.jpg" alt="雄琴ソープ" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.cityheaven.net/kyoto/A2603/A260301/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/06_maiduru.jpg" alt="舞鶴デリヘル/風俗" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.cityheaven.net/kyoto/A2603/A260301/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/07_fukuchiyama.jpg" alt="福知山デリヘル/風俗" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.cityheaven.net/shiga/A2503/A250301/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/08_hikone.jpg" alt="彦根デリヘル/風俗" width="58" height="34" border="0" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center",  marginBottom: "10px"}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div><a style={{display: "block"}} href="https://www.girlsheaven-job.net/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/g_heaven.gif" alt="ガールズヘブン" width="236" height="68" border="0" /></a></div>
                        <div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <a style={{display: "block"}}  href="https://www.girlsheaven-job.net/shiga/ma-52/sa-479/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/01_ogoto.jpg" alt="雄琴風俗求人" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.girlsheaven-job.net/shiga/ma-52/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/02_otsu.jpg" alt="大津風俗求人" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.girlsheaven-job.net/kyoto/ma-51/sa-477/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/03_gion.jpg" alt="祇園風俗求人" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.girlsheaven-job.net/kyoto/ma-51/sa-476/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/04_kawaramachi.jpg" alt="河原町風俗求人" width="58" height="34" border="0" /></a>
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <a style={{display: "block"}} href="https://www.girlsheaven-job.net/osaka/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/05_osaka.jpg" alt="大阪風俗求人" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.girlsheaven-job.net/kyoto/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/06_kyoto.jpg" alt="京都風俗求人" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.girlsheaven-job.net/shiga/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/07_shiga.jpg" alt="滋賀風俗求人" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://www.girlsheaven-job.net/hyogo/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/08_hyogo.jpg" alt="兵庫風俗求人" width="58" height="34" border="0" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center",  marginBottom: "10px"}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div>
                        <a style={{display: "block"}} href="https://mensheaven.jp/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/j_heaven.gif" alt="ジョブヘブン" width="236" height="68" border="0" /></a>
                        </div>
                        <div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <a style={{display: "block"}}  href="https://mensheaven.jp/8/shiga/ma-52/sa-479/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/01_ogoto.jpg" alt="雄琴男性高収入" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://mensheaven.jp/8/shiga/ma-52/sa-480/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/02_otsu.jpg" alt="大津男性高収入" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://mensheaven.jp/8/kyoto/ma-51/sa-477/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/03_gion.jpg" alt="祇園男性高収入" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://mensheaven.jp/8/kyoto/ma-51/sa-476/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/04_kawaramachi.jpg" alt="河原町男性高収入" width="58" height="34" border="0" /></a>
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <a style={{display: "block"}} href="https://mensheaven.jp/8/osaka/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/05_osaka.jpg" alt="大阪男性高収入" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://mensheaven.jp/8/kyoto/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/06_kyoto.jpg" alt="京都男性高収入" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://mensheaven.jp/8/shiga/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/07_shiga.jpg" alt="滋賀男性高収入" width="58" height="34" border="0" /></a>
                                <a style={{display: "block"}} href="https://mensheaven.jp/8/hyogo/shop-list/" target="_blank" rel="noopener"><img  style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/08_hyogo.jpg" alt="兵庫男性高収入" width="58" height="34" border="0" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )

}

export default Enterence()